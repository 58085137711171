// import React, { useEffect, useState } from 'react'
import { API_HOME_BASE_URL } from '../../../config';
import './Category.css'
import { Link } from 'react-router-dom';
function Category () {
    // const [categ, setCateg] = useState();
    // useEffect( ()=> {
    //     //Aos.init();

    //     //getLatestInArticle();
    //     getCateg();
    //     //getData();
    // });

    // async function getCateg()
    // {
    //     let result = await fetch(`${API_BASE_URL}/industrial-categories`);
    //     result = await result.json();
    //     setCateg(result);
    //     //console.log(categ);
    // }
   

    return (
        <>
        <div className='container' style={{"marginTop":"15%"}}>
            <div className='row industry'><h3>Industries</h3></div>
            <div className="row">
                <div className="col-md-3 industry-oi-cat">   
                    <Link to="/medtech" className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/medtech.png`} alt='Medtech / Health Care'/>
                        <br/>
                        <h6 className="industries-item-content">Medtech / Health Care</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/fintech"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/fintech.png`} alt='Fintech / Finance'/>
                        <br/>
                        <h6 className="industries-item-content">Fintech / Finance</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/nanotech"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/nanotech.jpg`} alt='Nanotech'/>
                        <br/>
                        <h6 className="industries-item-content">Nanotech</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/biotech"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/biotech.jpg`} alt='Biotech / Bio chemical / Chemical Engineering'/>
                        <br/>
                        <h6 className="industries-item-content">Biotech / Bio chemical / Chemical Engineering</h6>
                        
                    </Link>
                </div>
                
                
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/high-tech"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/hitech.png`} alt='High Tech'/>
                        <br/>
                        <h6 className="industries-item-content">High Tech</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/insurtech"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/insurtech.png`} alt='Insurtech'/>
                        <br/>
                        <h6 className="industries-item-content">Insurtech</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/agriculture"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/agri.png`} alt='Agrotech / Agriculture'/>
                        <br/>
                        <h6 className="industries-item-content">Agrotech / Agriculture</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/information-technology"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/infotech.png`} alt='Information Technology'/>
                        <br/>
                        <h6 className="industries-item-content">Information Technology</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/edutech" className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/edutech.png`} alt='Edu Tech'/>
                        <br/>
                        <h6 className="industries-item-content">Edu Tech</h6>
                        
                    </Link>
                </div>
                
                
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/metal" className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/metal.png`} alt='Metal Industry'/>
                        <br/>
                        <h6 className="industries-item-content">Metal Industry</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/construction"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/construction.png`} alt='Construction'/>
                        <br/>
                        <h6 className="industries-item-content">Construction</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/manufacturing"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/manufacturing.png`} alt='Manufacturing'/>
                        <br/>
                        <h6 className="industries-item-content">Manufacturing</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/geospatial"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/geospatial.png`} alt='Geospatial'/>
                        <br/>
                        <h6 className="industries-item-content">Geospatial</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/allied"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/allied.png`} alt='Allied Industries'/>
                        <br/>
                        <h6 className="industries-item-content">Allied Industries </h6>
                        
                    </Link>
                </div>
                
               
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/utilities-and-energy" className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/energy.png`} alt='Utilities and Energy - Solar energy / Renewable'/>
                        <br/>
                        <h6 className="industries-item-content">Utilities and Energy - Solar energy / Renewable</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/age-care-and-retirement"  className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/retirement.png`} alt='Age Care And Retirement'/>
                        <br/>
                        <h6 className="industries-item-content">Age Care And Retirement</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/industrial-equipment" className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/industrial.png`} alt='Industrial Equipment'/>
                        <br/>
                        <h6 className="industries-item-content">Industrial Equipment</h6>
                        
                    </Link>
                </div>
                <div className="col-md-3 industry-oi-cat">
                    <Link to="/cruise-and-tourism" className="industry-oi-cat-item ">
                        <img src={`${API_HOME_BASE_URL}/public/uploads/industries/cruise.png`} alt='Cruise & Tourism'/>
                        <br/>
                        <h6 className="industries-item-content">Cruise & Tourism</h6>
                        
                    </Link>
                </div>
            
            </div>
        </div>
            
        </>
    )
}

export default Category;